import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Avatar,
  Button,
  LinearProgress,
} from "@mui/material";
import { Rating } from "@mui/lab";
import { green, red, blue, yellow } from "@mui/material/colors";
import DoneIcon from "@mui/icons-material/Done";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import InfoIcon from "@mui/icons-material/Info";
import StarIcon from "@mui/icons-material/Star";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Bar } from "react-chartjs-2";
import { motion } from "framer-motion";
import { getAuth } from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { useReactToPrint } from "react-to-print";

function createData(question, response, score, feedback) {
  return { question, response, score, feedback };
}

function getScoreColor(score) {
  if (score >= 8) return green[500];
  if (score >= 6) return blue[500];
  return red[500];
}

function getFeedbackIcon(score) {
  if (score >= 8) {
    return <DoneIcon sx={{ color: green[500] }} />;
  } else if (score >= 6) {
    return <InfoIcon sx={{ color: blue[500] }} />;
  } else {
    return <PriorityHighIcon sx={{ color: red[500] }} />;
  }
}

function Analysis() {
  const [rows, setRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const reportRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (!currentUser) return;

      const simulationsCollection = collection(db, "simulations");
      const q = query(
        simulationsCollection,
        where("userid", "==", currentUser.email),
        where("status", "==", "Terminé")
      );

      const querySnapshot = await getDocs(q);
      const simulations = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      const allRows = simulations.flatMap((simulation) =>
        simulation.detail.map((q) =>
          createData(q.question, q.response, q.rating, q.feedback)
        )
      );

      setRows(allRows);
    };

    fetchData();
  }, []);

  const handlePageChange = (direction) => {
    setCurrentPage((prevPage) => prevPage + direction);
  };

  const displayedRows = rows.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(rows.length / itemsPerPage);

  const handlePrint = useReactToPrint({
    content: () => reportRef.current,
    documentTitle: "Analyse des réponses",
  });

  const chartData = {
    labels: rows.map((row) => row.question),
    datasets: [
      {
        label: "Scores",
        data: rows.map((row) => row.score),
        backgroundColor: rows.map((row) => getScoreColor(row.score)),
      },
    ],
  };

  return (
    <Box sx={{ p: 3 }} ref={reportRef}>
      <Typography variant="h4" gutterBottom>
        Analyse Détailée des Réponses
      </Typography>
      <Typography variant="body1" gutterBottom>
        Voici une analyse détaillée de vos réponses, scores et feedbacks.
      </Typography>

      <LinearProgress
        variant="determinate"
        value={(currentPage / totalPages) * 100}
        sx={{ my: 2 }}
      />

      <Grid container spacing={3}>
        {displayedRows.map((row, index) => (
          <Grid item xs={12} md={6} key={index}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  "&:hover": { transform: "scale(1.05)", boxShadow: 6 },
                }}
                elevation={3}
              >
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Avatar sx={{ mr: 2, bgcolor: yellow[700] }}>
                      <StarIcon />
                    </Avatar>
                    <Typography variant="h6">{row.question}</Typography>
                  </Box>
                  <Typography variant="body1" color="textSecondary" paragraph>
                    <strong>Réponse :</strong> {row.response}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Rating value={row.score / 2} readOnly />
                    <Typography
                      variant="body1"
                      sx={{ ml: 2, color: getScoreColor(row.score), fontWeight: "bold" }}
                    >
                      {row.score} / 10
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary">
                    <strong>Feedback :</strong> {row.feedback}
                  </Typography>
                  <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                    {getFeedbackIcon(row.score)}
                  </Box>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
        <Button
          variant="contained"
          startIcon={<ArrowBackIcon />}
          disabled={currentPage === 1 || rows.length === 0}
          onClick={() => handlePageChange(-1)}
          sx={{ mr: 2 }}
        >
          Précédent
        </Button>
        <Typography variant="body1" sx={{ alignSelf: "center" }}>
          Page {currentPage} sur {totalPages}
        </Typography>
        <Button
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          disabled={currentPage === totalPages || rows.length === 0}
          onClick={() => handlePageChange(1)}
          sx={{ ml: 2 }}
        >
          Suivant
        </Button>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <Button variant="contained" color="secondary" onClick={handlePrint}>
          Imprimer en PDF
        </Button>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">Visualisation des Scores</Typography>
        <Bar data={chartData} />
      </Box>
    </Box>
  );
}

export default Analysis;
