import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  Paper,
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
  Stack,
  Snackbar,
} from "@mui/material";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import HistoryIcon from "@mui/icons-material/History";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { getAuth } from "firebase/auth";
import {
  collection,
  query,
  where,
  getDocs,
  Timestamp,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import { fetchUserSubscriptions } from "../fetchUserSubscriptions";
import { generateAndStoreSimulations, fetchOpenAiData } from "../api/fetchOpenAiData";

// Enregistrement des composants nécessaires pour Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Simulations() {
  const navigate = useNavigate();
  const [simulation, setSimulation] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pastSimulations, setPastSimulations] = useState([]);
  const [subscriptionInfo, setSubscriptionInfo] = useState({ plan: "", status: "" });
  const [allFeaturesLocked, setAllFeaturesLocked] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const subscriptions = await fetchUserSubscriptions(setSubscriptionInfo);
        if (subscriptions.length > 0) {
          const user = subscriptions[0];
          setSubscriptionInfo({
            plan: user.name,
            status: user.status,
          });
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des informations d'abonnement :", error);
      }
    };

    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (currentUser) {
      fetchPastSimulations(currentUser.email);
      fetchUserData();
    }
  }, []);

  const fetchPastSimulations = async (userId) => {
    const simulationsCollection = collection(db, "simulations");
    const q = query(
      simulationsCollection,
      where("userid", "==", userId),
      where("status", "==", "Terminé")
    );
    const querySnapshot = await getDocs(q);
    const simulationsData = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
        timestamp:
          data.timestamp instanceof Timestamp
            ? data.timestamp
            : Timestamp.fromDate(new Date(data.timestamp)),
      };
    });

    const sortedSimulations = simulationsData.sort(
      (a, b) => b.timestamp.seconds - a.timestamp.seconds
    );

    setPastSimulations(sortedSimulations);
  };

  const handleGenerateSimulation = async () => {
    setLoading(true);
    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (!currentUser) {
        throw new Error("Utilisateur non connecté");
      }

      const userData = await fetchOpenAiData(currentUser);
      if (!userData) {
        throw new Error("Données utilisateur introuvables");
      }

      const simulationData = await generateAndStoreSimulations(userData, currentUser);
      setSimulation(simulationData);
      navigate("/simulationinterview");
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleViewResources = () => {
    navigate("/guidesressource");
  };

  const progressPercentage = Math.min((pastSimulations.length / 3) * 100, 100);

  const data = {
    labels: pastSimulations.map((sim, index) => `Simulation ${index + 1}`),
    datasets: [
      {
        label: "Scores",
        data: pastSimulations.map((sim) => sim.globalScore || 0),
        backgroundColor: "rgba(75,192,192,0.6)",
      },
    ],
  };

  return (
    <Box sx={{ p: 3 }}>
      {allFeaturesLocked && (
        <Alert severity="warning" sx={{ mb: 3 }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography>
              Vous avez épuisé votre crédit de test. Pour accéder à toutes les fonctionnalités, veuillez souscrire à un abonnement.
            </Typography>
            <Button variant="contained" color="primary" onClick={() => navigate("/subscriptionPlans")}>
              Abonnement
            </Button>
          </Stack>
        </Alert>
      )}

      <Typography variant="h4" gutterBottom>
        Simulations
      </Typography>
      <Typography variant="body1" gutterBottom>
        Pratiquez vos compétences en entretien avec des simulations interactives. Vous pouvez commencer une nouvelle simulation ou revoir vos performances passées.
      </Typography>

      <Grid container spacing={3}>
        {/* Carte: Nouvelle Simulation */}
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              p: 2,
              textAlign: "center",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              "&:hover": { transform: "scale(1.05)", boxShadow: 6 },
            }}
            elevation={3}
          >
            <PlayCircleFilledIcon sx={{ fontSize: 50, color: "primary.main", mb: 1 }} />
            <Typography variant="h6" gutterBottom>
              Nouvelle Simulation
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleGenerateSimulation}
              disabled={loading || (!subscriptionInfo.plan && pastSimulations.length > 3)}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : "Démarrer une Simulation"}
            </Button>
          </Paper>
        </Grid>

        {/* Carte: Simulations Passées */}
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              p: 2,
              textAlign: "center",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              "&:hover": { transform: "scale(1.05)", boxShadow: 6 },
            }}
            elevation={3}
          >
            <HistoryIcon sx={{ fontSize: 50, color: "secondary.main", mb: 1 }} />
            <Typography variant="h6" gutterBottom>
              Simulations Passées
            </Typography>
            <List sx={{ maxHeight: 200, overflow: "auto" }}>
              {pastSimulations.map((simulation, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={`Date: ${simulation.timestamp.toDate().toLocaleDateString()} - Score: ${simulation.globalScore || 0}`}
                    secondary={`Feedback: ${simulation.pointsaameliorer || "Aucun"}`}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>

        {/* Carte: Ressources d'Entraînement */}
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              textAlign: "center",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              "&:hover": { transform: "scale(1.05)", boxShadow: 6 },
            }}
            elevation={3}
          >
            <MenuBookIcon sx={{ fontSize: 50, color: "action.active", mb: 1 }} />
            <Typography variant="h6" gutterBottom>
              Ressources d'Entraînement
            </Typography>
            <Typography variant="body1" gutterBottom>
              Accédez à des vidéos, des articles et d'autres ressources pour améliorer vos compétences en entretien.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleViewResources}
              disabled={!subscriptionInfo.plan}
            >
              Voir les Ressources
            </Button>
          </Paper>
        </Grid>

        {/* Carte: Graphique des Résultats */}
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              textAlign: "center",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              "&:hover": { transform: "scale(1.05)", boxShadow: 6 },
            }}
            elevation={3}
          >
            <Typography variant="h6" gutterBottom>
              Graphique des Résultats
            </Typography>
            <Bar data={data} />
          </Paper>
        </Grid>
      </Grid>

      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={3000}
        onClose={() => setSnackbarMessage("")}
        message={snackbarMessage}
      />
    </Box>
  );
}

export default Simulations;
