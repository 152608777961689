import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Paper,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
  Stack,
  Snackbar,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import AssessmentIcon from "@mui/icons-material/Assessment";
import RestoreIcon from "@mui/icons-material/Restore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { db } from "../firebaseConfig";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useAuth } from "../authContext";
import { fetchUserSubscriptions } from "../fetchUserSubscriptions";

// Enregistrement explicite des composants nécessaires pour Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Preparation() {
  const [pastEvaluations, setPastEvaluations] = useState([]);
  const [subscriptionInfo, setSubscriptionInfo] = useState({ plan: "", status: "" });
  const [totalQuizTests, setTotalQuizTests] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [allFeaturesLocked, setAllFeaturesLocked] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const subscriptions = await fetchUserSubscriptions(setSubscriptionInfo);
        if (subscriptions.length > 0) {
          const user = subscriptions[0];
          setSubscriptionInfo({
            plan: user.name,
            status: user.status,
          });
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des informations d'abonnement :", error);
      }
    };

    const fetchPastEvaluations = async () => {
      if (currentUser) {
        const q = query(
          collection(db, "quiztest"),
          where("userid", "==", currentUser.email),
          where("status", "==", "Terminé")
        );
        const querySnapshot = await getDocs(q);
        const evaluations = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          evaluations.push({
            timestamp: data.timestamp,
            score: data.score,
          });
        });
        evaluations.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        setPastEvaluations(evaluations);
        setTotalQuizTests(querySnapshot.size);
      }
    };

    fetchUserData();
    fetchPastEvaluations();
  }, [currentUser]);

  useEffect(() => {
    const featuresLocked = !subscriptionInfo.plan && totalQuizTests > 3;
    setAllFeaturesLocked(featuresLocked);
  }, [subscriptionInfo.plan, totalQuizTests]);

  const data = {
    labels: pastEvaluations.map((_, index) => `Test ${index + 1}`),
    datasets: [
      {
        label: "Scores",
        data: pastEvaluations.map((evaluation) => evaluation.score),
        backgroundColor: "rgba(75,192,192,0.6)",
      },
    ],
  };

  const handleSubscriptionClick = () => {
    navigate("/subscriptionPlans");
  };

  return (
    <Box sx={{ p: 3 }}>
      {allFeaturesLocked && (
        <Alert severity="warning" sx={{ mb: 3 }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography>
              Vous avez épuisé votre crédit de test. Pour accéder à toutes les fonctionnalités, veuillez souscrire à un abonnement.
            </Typography>
            <Button variant="contained" color="primary" onClick={handleSubscriptionClick}>
              Abonnement
            </Button>
          </Stack>
        </Alert>
      )}
      <Typography variant="h4" gutterBottom>
        Préparation
      </Typography>
      <Typography variant="body1" gutterBottom>
        Accédez à des ressources et des outils pour vous aider à vous préparer pour vos entretiens et réussir dans vos candidatures.
      </Typography>
      <Grid container spacing={3}>
        {[
          {
            title: "Guides et Ressources",
            description: "Guides, astuces et conseils utiles pour vous aider à préparer vos entretiens.",
            icon: <MenuBookIcon />,
            link: "/guidesressource",
            disabled: !subscriptionInfo.plan,
          },
          {
            title: "Questions d'Entretien Courantes",
            description: "Liste des questions courantes avec des exemples de réponses.",
            icon: <QuestionAnswerIcon />,
            link: "/interviewquestions",
            disabled: !subscriptionInfo.plan,
          },
          {
            title: "Simulations d'Entretien",
            description: "Pratiquez vos entretiens avec des simulations et obtenez du feedback.",
            icon: <PlayCircleFilledIcon />,
            link: "/simulations",
          },
          {
            title: "Évaluation de Compétences",
            description: "Testez vos compétences techniques et non techniques.",
            icon: <AssessmentIcon />,
            link: "/evaluation",
            disabled: !subscriptionInfo.plan && totalQuizTests > 3,
          },
        ].map((item, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Paper
              sx={{
                p: 2,
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": { transform: "scale(1.05)", boxShadow: 6 },
              }}
              elevation={3}
            >
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                {item.icon}
                <Typography variant="h6" gutterBottom>
                  {item.title}
                </Typography>
              </Box>
              <Typography variant="body2">{item.description}</Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                disabled={item.disabled}
              >
                <Link to={item.link} style={{ color: "inherit", textDecoration: "none" }}>
                  Accéder
                </Link>
              </Button>
            </Paper>
          </Grid>
        ))}
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              p: 2,
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": { transform: "scale(1.05)", boxShadow: 6 },
            }}
            elevation={3}
          >
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <RestoreIcon sx={{ mr: 1 }} />
              <Typography variant="h6" gutterBottom>
                Évaluations Passées
              </Typography>
            </Box>
            <Typography variant="body2">
              Consultez les résultats de vos évaluations passées.
            </Typography>
            <Box sx={{ maxHeight: 200, overflowY: "auto", mt: 2 }}>
              {pastEvaluations.length > 0 ? (
                pastEvaluations.map((evaluation, index) => (
                  <Box key={index} sx={{ mt: 2, display: "flex", alignItems: "center" }}>
                    <Typography variant="body2">
                      Date: {new Date(evaluation.timestamp).toLocaleDateString()} - Score: {evaluation.score}/10
                    </Typography>
                    {evaluation.score > 7 ? (
                      <CheckCircleIcon color="success" sx={{ ml: 1 }} />
                    ) : (
                      <ErrorIcon color="error" sx={{ ml: 1 }} />
                    )}
                  </Box>
                ))
              ) : (
                <Typography variant="body2" sx={{ mt: 2 }}>
                  Aucun résultat d'évaluation passé.
                </Typography>
              )}
            </Box>
            <Bar data={data} />
          </Paper>
        </Grid>
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        message="Veuillez souscrire à un abonnement pour accéder à cette fonctionnalité."
        onClose={() => setOpenSnackbar(false)}
      />
    </Box>
  );
}

export default Preparation;
