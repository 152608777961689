import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Paper,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  LinearProgress
} from '@mui/material';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from "../firebaseConfig";
import { useAuth } from "../authContext";
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';

const InterviewCandidat = () => {
  const { offerId } = useParams();  // Récupère offerId de l'URL
  const { currentUser } = useAuth();  // Récupère les informations de l'utilisateur connecté
  const currentUserEmail = currentUser?.email;
  const navigate = useNavigate();  // Utilisation de useNavigate pour la redirection

  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userResponse, setUserResponse] = useState('');
  const [offerTitle, setOfferTitle] = useState('');
  const [timeLeft, setTimeLeft] = useState(30 * 60); // 30 minutes
  const [timeUp, setTimeUp] = useState(false);
  const [isMicrophoneEnabled, setIsMicrophoneEnabled] = useState(false);
  const [recognition, setRecognition] = useState(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [utterance, setUtterance] = useState(null);
  const [interviewCompleted, setInterviewCompleted] = useState(false);
  const [instructionDialogOpen, setInstructionDialogOpen] = useState(true); // State to control instruction dialog visibility

  // Fetch interview questions
  const fetchInterviewQuestions = async () => {
    try {
      const offerDoc = await getDoc(doc(db, 'offres_emploi', offerId));
      if (offerDoc.exists()) {
        const data = offerDoc.data();
        setOfferTitle(data.titre);
        setQuestions(Array.isArray(data.l_interview) ? data.l_interview : []);
  
        // Set the initial interview duration
        if (data.duree_interview) {
          setTimeLeft(data.duree_interview * 60);  // Assuming duree_interview is in minutes
        }
  
        const candidateIndex = data.l_candidats.findIndex(c => c.userID === currentUserEmail);
        if (candidateIndex !== -1) {
          data.l_candidats[candidateIndex].interview_status = "ongoing"; // Update interview status to ongoing
  
          await updateDoc(doc(db, 'offres_emploi', offerId), {
            l_candidats: data.l_candidats
          });
  
          speakInstruction(); // Speak the instructional message
        }
      } else {
        console.error('No such document!');
      }
    } catch (error) {
      console.error('Error fetching document:', error);
    }
  };
  

  // Speak the instructional message at the start of the interview
  const speakInstruction = () => {
    const instruction = "Attention, l'interview va commencer. Pour répondre, écrivez dans la zone de texte ou activez le micro pour parler et désactivez-le pour terminer. Ne fermez pas cette fenêtre tant que vous n'avez pas terminé.";
    speakText(instruction);
  };

  // Speak the current question with a masculine voice in French
  const speakQuestion = (question) => {
    speakText(question);
  };

  const speakText = (text) => {
    if ('speechSynthesis' in window) {
      if (utterance) {
        window.speechSynthesis.cancel();
      }

      const newUtterance = new SpeechSynthesisUtterance(text);
      newUtterance.lang = 'fr-FR';

      const voices = window.speechSynthesis.getVoices();
      const frenchMaleVoice = voices.find(voice =>
        voice.lang.startsWith('fr') &&
        voice.gender === 'male'
      );

      if (frenchMaleVoice) {
        newUtterance.voice = frenchMaleVoice;
      } else {
        console.warn('Aucune voix masculine française disponible, utilisation de la voix par défaut.');
      }

      newUtterance.onstart = () => setIsSpeaking(true);
      newUtterance.onend = () => setIsSpeaking(false);
      window.speechSynthesis.speak(newUtterance);

      setUtterance(newUtterance);
    } else {
      console.error('Speech synthesis not supported in this browser.');
    }
  };

  const handleNextQuestion = () => {
    if (timeUp) return;
    setUserResponse('');
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      speakQuestion(questions[currentQuestionIndex + 1]);
    } else {
      handleEndInterview();
    }
  };

  const handlePassQuestion = () => {
    if (timeUp) return;
    setUserResponse('');
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      speakQuestion(questions[currentQuestionIndex + 1]);
    } else {
      handleEndInterview();
    }
  };

  const handleSubmitResponse = async () => {
    if (timeUp) return;
    if (userResponse.trim()) {
      try {
        await updateCandidateResponse(questions[currentQuestionIndex], userResponse);
        handleNextQuestion();
      } catch (error) {
        console.error('Error updating candidate response:', error);
      }
    }
  };

  const updateCandidateResponse = async (question, response) => {
    try {
      const offerDocRef = doc(db, 'offres_emploi', offerId);
      const offerDoc = await getDoc(offerDocRef);

      if (offerDoc.exists()) {
        const data = offerDoc.data();

        if (Array.isArray(data.l_candidats)) {
          const candidateIndex = data.l_candidats.findIndex(c => c.userID === currentUserEmail);

          if (candidateIndex !== -1) {
            let interviewSession = data.interview_session || [];

            interviewSession.push({
              idcandidat: currentUserEmail,
              question: question,
              reponse: response,
              commentaire: "",  // Add empty commentaire field
              note: 0  // Add initial note of 0
            });

            data.l_candidats[candidateIndex].interview_status = "ongoing"; // Ensure interview status is ongoing

            await updateDoc(offerDocRef, {
              interview_session: interviewSession,
              l_candidats: data.l_candidats
            });

            console.log('Interview session updated successfully.');
          } else {
            console.warn('Candidate not found in the document.');
          }
        } else {
          console.warn('l_candidats is undefined or not an array.');
        }
      } else {
        console.error('Offer document not found.');
      }
    } catch (error) {
      console.error('Error updating interview session:', error);
      throw new Error('Erreur lors de la mise à jour de la session d\'entretien.');
    }
  };

  const handleEndInterview = async () => {
    setTimeUp(true);
    setInterviewCompleted(true);
  
    try {
      const offerDocRef = doc(db, 'offres_emploi', offerId);
      const offerDoc = await getDoc(offerDocRef);
  
      if (offerDoc.exists()) {
        const data = offerDoc.data();
        const candidateIndex = data.l_candidats.findIndex(c => c.userID === currentUserEmail);
  
        if (candidateIndex !== -1) {
          // Calculate the time consumed by the candidate
          const totalInterviewTime = data.duree_interview * 60;  // Assuming duree_interview is in minutes
          const timeConsumed = totalInterviewTime - timeLeft;  // Time consumed in seconds
  
          // Update the candidate's interview status and time consumed
          data.l_candidats[candidateIndex].interview_status = "finished";
          data.l_candidats[candidateIndex].duree_interview_passee = timeConsumed / 60; // Save in minutes
  
          await updateDoc(offerDocRef, {
            l_candidats: data.l_candidats
          });
  
          console.log('Interview status updated to finished and time consumed recorded.');
        } else {
          console.warn('Candidate not found in the document.');
        }
      } else {
        console.error('Offer document not found.');
      }
    } catch (error) {
      console.error('Error updating interview status and time consumed:', error);
    }
  
    setTimeout(() => {
      alert("L'interview est terminée. Merci d'avoir participé.");
      navigate('/dashboard');  // Redirection vers le Dashboard
    }, 500);
  };
  


  const handleCloseInstructionDialog = () => {
    setInstructionDialogOpen(false);
  };

  const handleCloseDialog = () => {
    setTimeUp(false);
    window.location.reload();
  };

  const handleMicrophoneToggle = () => {
    if (timeUp) return;
    if (!isMicrophoneEnabled) {
      startRecording();
    } else {
      stopRecording();
    }
    setIsMicrophoneEnabled(!isMicrophoneEnabled);
  };

  const startRecording = () => {
    if ('webkitSpeechRecognition' in window) {
      const recog = new window.webkitSpeechRecognition();
      recog.lang = 'fr-FR';
      recog.continuous = true;
      recog.interimResults = false;

      recog.onstart = () => setIsSpeaking(true);
      recog.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map(result => result[0].transcript)
          .join('');
        setUserResponse(transcript);
      };
      recog.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
        setIsSpeaking(false);
      };
      recog.onend = () => {
        setIsSpeaking(false);
        if (isMicrophoneEnabled) {
          startRecording();
        }
      };

      recog.start();
      setRecognition(recog);
    } else {
      console.error('Speech recognition not supported in this browser.');
    }
  };

  const stopRecording = () => {
    if (recognition) {
      recognition.stop();
      setRecognition(null);
    }
    setIsSpeaking(false);
  };

  useEffect(() => {
    fetchInterviewQuestions();
  }, [offerId]);

  useEffect(() => {
    if (questions.length > 0 && currentQuestionIndex < questions.length) {
      speakQuestion(questions[currentQuestionIndex]);
    }
  }, [currentQuestionIndex, questions]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setTimeUp(true);
      handleEndInterview();
    }
  }, [timeLeft]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <Box sx={{ padding: 3, maxWidth: 800, margin: 'auto', textAlign: 'center' }}>
      <Typography variant="h5" gutterBottom>
        Entretien pour le poste de {offerTitle}
      </Typography>

      <Box sx={{ marginY: 2 }}>
        <LinearProgress
          variant="determinate"
          value={(currentQuestionIndex + 1) / questions.length * 100}
          sx={{ marginBottom: 2 }}
        />
      </Box>

      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Temps restant : {formatTime(timeLeft)}
      </Typography>

      {questions.length > 0 ? (
        <Paper sx={{ padding: 2 }}>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            Question {currentQuestionIndex + 1}: {questions[currentQuestionIndex]}
          </Typography>
          <TextField
            value={userResponse}
            onChange={(e) => setUserResponse(e.target.value)}
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            placeholder="Votre réponse ici..."
            disabled={timeUp || interviewCompleted}
          />
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
            <Button
              variant="contained"
              onClick={handleSubmitResponse}
              disabled={!userResponse || timeUp || interviewCompleted}
              sx={{ marginRight: 1 }}
            >
              Soumettre la réponse
            </Button>
            <Button
              variant="outlined"
              onClick={handlePassQuestion}
              sx={{ marginRight: 1 }}
              disabled={timeUp || interviewCompleted}
            >
              Passer
            </Button>
            <IconButton
              onClick={handleMicrophoneToggle}
              color={isMicrophoneEnabled ? 'secondary' : 'primary'}
              disabled={timeUp || interviewCompleted}
            >
              {isMicrophoneEnabled ? <MicIcon /> : <MicOffIcon />}
            </IconButton>
          </Box>
        </Paper>
      ) : (
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          Chargement des questions...
        </Typography>
      )}

      <Dialog open={instructionDialogOpen} onClose={handleCloseInstructionDialog}>
        <DialogTitle>Instructions</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Attention, l'interview va commencer. Pour répondre, écrivez dans la zone de texte ou activez le micro pour parler et désactivez-le pour terminer. Ne fermez pas cette fenêtre tant que vous n'avez pas terminé.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInstructionDialog} color="primary">OK</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={timeUp} onClose={handleCloseDialog}>
        <DialogTitle>Interview terminée</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            L'interview est terminée. Merci d'avoir participé.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">OK</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default InterviewCandidat;
