import React, { useEffect, useState } from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Avatar,
  Grid,
  Card,
  CardContent,
  Button,
  Alert,
  Stack,
} from "@mui/material";
import {
  DirectionsRun as DirectionsRunIcon,
  Chat as ChatIcon,
  Assessment as AssessmentIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { fetchUserSubscriptions } from "../fetchUserSubscriptions";
import { useAuth } from "../authContext";
import "./Home.css"; // Import du fichier CSS

function Home() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [subscriptionInfo, setSubscriptionInfo] = useState({ plan: "", status: "" });
  const [totalQuizTests, setTotalQuizTests] = useState(0);
  const [totalSimulations, setTotalSimulations] = useState(0);

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        try {
          const subscriptions = await fetchUserSubscriptions(setSubscriptionInfo);
          if (subscriptions.length > 0) {
            const user = subscriptions[0];
            setSubscriptionInfo({ plan: user.name, status: user.status });
          }

          const quizQuery = query(
            collection(db, "quiztest"),
            where("userid", "==", currentUser.email),
            where("status", "==", "Terminé")
          );
          const quizSnapshot = await getDocs(quizQuery);
          setTotalQuizTests(quizSnapshot.size);

          const simulationQuery = query(
            collection(db, "simulations"),
            where("userid", "==", currentUser.email),
            where("status", "==", "Terminé")
          );
          const simulationSnapshot = await getDocs(simulationQuery);
          setTotalSimulations(simulationSnapshot.size);
        } catch (error) {
          console.error("Erreur lors de la récupération des données utilisateur :", error);
        }
      }
    };

    fetchUserData();
  }, [currentUser]);

  const handlePreparationClick = () => navigate("/preparation");
  const handleSimulationsClick = () => navigate("/simulations");

  const showInviteMessage =
    totalQuizTests + totalSimulations < 3 && !subscriptionInfo.plan;

  const testimonials = [
    {
      name: "Laruche",
      comment: "JobJourney m'a vraiment aidé à me préparer pour mes entretiens. Les simulations sont très réalistes!",
      avatar: "https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg", // Femme souriante en extérieur
    },
    {
      name: "Mareline Mayella",
      comment: "Grâce à JobJourney, j'ai pu identifier mes points faibles et m'améliorer rapidement.",
      avatar: "https://images.pexels.com/photos/1181686/pexels-photo-1181686.jpeg", // Homme avec fond lumineux
    },
    {
      name: "Konate",
      comment: "Les feedbacks personnalisés sont excellents. Je recommande vivement cette application!",
      avatar: "https://images.pexels.com/photos/936075/pexels-photo-936075.jpeg", // Homme souriant avec t-shirt noir
    },
    {
      name: "Chaboui Dieudonné",
      comment: "Les analyses détaillées m'ont aidé à comprendre où je pouvais m'améliorer.",
      avatar: "https://images.pexels.com/photos/1866149/pexels-photo-1866149.jpeg", // Homme avec chemise
    },
    {
      name: "Aminata Diallo",
      comment: "Une application très intuitive qui m'a permis de me sentir prête pour mes entretiens.",
      avatar: "https://images.pexels.com/photos/1181519/pexels-photo-1181519.jpeg", // Femme souriante en extérieur
    },
    {
      name: "Cheikh Ndiaye",
      comment: "Le feedback reçu a été extrêmement utile pour mes entretiens réels.",
      avatar: "https://images.pexels.com/photos/2422434/pexels-photo-2422434.jpeg", // Homme en chemise bleue
    },
  ];

  return (
    <div>
      <Typography variant="h3" gutterBottom>
        Bienvenue sur JobJourney!
      </Typography>
      <Typography variant="body1" gutterBottom>
        L'intelligence artificielle dédiée à vous aider dans votre parcours professionnel. Elle vous offre plusieurs fonctionnalités essentielles :
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <DirectionsRunIcon />
          </ListItemIcon>
          <ListItemText primary="Préparation aux entretiens" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <ChatIcon />
          </ListItemIcon>
          <ListItemText primary="Simulations d'entretiens" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <AssessmentIcon />
          </ListItemIcon>
          <ListItemText primary="Analyses détaillées des réponses" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CheckCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Feedback personnalisé et rapports" />
        </ListItem>
      </List>

      {showInviteMessage && (
        <Alert severity="info" sx={{ mb: 3 }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography>
              Découvrez nos évaluations de connaissances et simulations d'entretiens pour améliorer vos compétences. Profitez-en!
            </Typography>
            <Button variant="contained" color="primary" onClick={handlePreparationClick}>
              Évaluations
            </Button>
            <Button variant="contained" color="secondary" onClick={handleSimulationsClick}>
              Simulations
            </Button>
          </Stack>
        </Alert>
      )}

      <div className="video-container">
        <iframe
          src="https://www.youtube.com/embed/2nSsUllWuoM"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <div className="testimonials">
        <Typography variant="h4" gutterBottom>
          Ce que disent nos Abonnés
        </Typography>
        <Grid container spacing={4}>
          {testimonials.map((testimonial, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Card>
                <CardContent>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Avatar src={testimonial.avatar} alt={testimonial.name} />
                    </Grid>
                    <Grid item xs>
                      <Typography variant="h6">{testimonial.name}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {testimonial.comment}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}

export default Home;
