import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";
import DescriptionIcon from "@mui/icons-material/Description";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { styled } from "@mui/system";
import { collection, getDocs, doc, deleteDoc, query, where, Timestamp } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { useAuth } from "../authContext";
import OffreForm from "./OffreForm";
import Candidature from "./Candidature";
import Questionnaireoffre from "./Questionnaireoffre";

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: 440,
}));

function OffreEmploi() {
  const { currentUser } = useAuth();
  const [offres, setOffres] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedOffre, setSelectedOffre] = useState(null);
  const [isCandidatureOpen, setIsCandidatureOpen] = useState(false);
  const [isQuestionnaireOpen, setIsQuestionnaireOpen] = useState(false);
  const [selectedOffreId, setSelectedOffreId] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [offreToDelete, setOffreToDelete] = useState(null);

  const fetchOffres = async () => {
    if (!currentUser) return;

    try {
      const offresCollection = collection(db, "offres_emploi");
      const offresQuery = query(
        offresCollection,
        where("ownerid", "==", currentUser.email)
      );
      const offresSnapshot = await getDocs(offresQuery);
      const offresList = offresSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setOffres(offresList);
    } catch (error) {
      console.error("Erreur lors de la récupération des offres d'emploi :", error);
    }
  };

  useEffect(() => {
    fetchOffres();
  }, [currentUser]);

  const handleAjouterOffre = () => {
    setSelectedOffre(null);
    setIsFormOpen(true);
  };

  const handleModifierOffre = (id) => {
    const offre = offres.find((offre) => offre.id === id);
    setSelectedOffre(offre);
    setIsFormOpen(true);
  };

  const handleFormSave = () => {
    setIsFormOpen(false);
    fetchOffres();
  };

  const handleFormCancel = () => {
    setIsFormOpen(false);
  };

  const handleSupprimerOffre = async () => {
    try {
      const offreRef = doc(db, "offres_emploi", offreToDelete);
      await deleteDoc(offreRef);
      fetchOffres();
      setDialogOpen(false);
    } catch (error) {
      console.error("Erreur lors de la suppression de l'offre :", error);
    }
  };

  const handleDeleteClick = (id) => {
    setOffreToDelete(id);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setOffreToDelete(null);
  };

  const handleAfficherCandidats = (id) => {
    setSelectedOffreId(id);
    setIsCandidatureOpen(true);
  };

  const handleGenererQuestionnaire = (id) => {
    setSelectedOffreId(id);
    setIsQuestionnaireOpen(true);
  };

  const handleCandidatureClose = () => {
    setIsCandidatureOpen(false);
    setSelectedOffreId(null);
  };

  const handleQuestionnaireClose = () => {
    setIsQuestionnaireOpen(false);
    setSelectedOffreId(null);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Offres d'Emploi
      </Typography>
      {!isFormOpen && !isCandidatureOpen && !isQuestionnaireOpen ? (
        <>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAjouterOffre}
            sx={{ mb: 2 }}
          >
            Ajouter Offre
          </Button>
          <StyledTableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Titre</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Lieu</TableCell>
                  <TableCell>Date de Publication</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {offres.map((offre) => (
                  <TableRow key={offre.id}>
                    <TableCell>
                      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        {offre.titre}
                        {offre.active ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>{offre.type}</TableCell>
                    <TableCell>{offre.lieu}</TableCell>
                    <TableCell>{new Timestamp(offre.datePublication.seconds, offre.datePublication.nanoseconds).toDate().toLocaleDateString()}</TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() => handleModifierOffre(offre.id)}
                        aria-label="Modifier"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => handleDeleteClick(offre.id)}
                        aria-label="Supprimer"
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={() => handleAfficherCandidats(offre.id)}
                        aria-label="Candidats"
                      >
                        <PersonIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={() => handleGenererQuestionnaire(offre.id)}
                        aria-label="Questionnaire"
                      >
                        <DescriptionIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </>
      ) : isFormOpen ? (
        <OffreForm
          selectedOffre={selectedOffre}
          onSave={handleFormSave}
          onCancel={handleFormCancel}
        />
      ) : isCandidatureOpen ? (
        <Candidature
          offreId={selectedOffreId}
          onClose={handleCandidatureClose}
        />
      ) : (
        <Questionnaireoffre
          offreId={selectedOffreId}
          onClose={handleQuestionnaireClose}
        />
      )}

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirmer la suppression</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes-vous sûr de vouloir supprimer cette offre d'emploi ? Cette action est irréversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Annuler
          </Button>
          <Button onClick={handleSupprimerOffre} color="error">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default OffreEmploi;
