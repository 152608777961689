import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Grid,
  Snackbar,
  Alert,
  Switch,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { db } from "../firebaseConfig";
import { doc, setDoc } from "firebase/firestore";
import { useAuth } from "../authContext";

function OffreForm({ selectedOffre, onSave, onCancel }) {
  const { currentUser } = useAuth();
  const [offre, setOffre] = useState({
    titre: "",
    type: "",
    lieu: "",
    datePublication: new Date().toISOString().split("T")[0],
    dateEntretien: "",
    duree_interview: "",
    description: "",
    active: true,
    offre_id: uuidv4(),
    ownerid: currentUser ? currentUser.email : "",
    categorie: "sur site", // Valeur par défaut "Sur site"
    date_demarrage: "",
    salaire: ""
  });

  const [error, setError] = useState("");

  useEffect(() => {
    if (selectedOffre) {
      setOffre({
        ...selectedOffre,
        datePublication: selectedOffre.datePublication.toDate().toISOString().split("T")[0],
        dateEntretien: selectedOffre.dateEntretien.toDate().toISOString().split("T")[0],
        date_demarrage: selectedOffre.date_demarrage ? selectedOffre.date_demarrage.toDate().toISOString().split("T")[0] : "",
        duree_interview: selectedOffre.duree_interview || "",
        active: selectedOffre.active,
        offre_id: selectedOffre.offre_id,
        ownerid: selectedOffre.ownerid,
        categorie: selectedOffre.categorie || "sur site", // Charger la catégorie ou valeur par défaut "Sur site"
        salaire: selectedOffre.salaire || ""
      });
    }
  }, [selectedOffre]);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setOffre({
      ...offre,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const pubDate = new Date(offre.datePublication);
    const entDate = new Date(offre.dateEntretien);
    const startDate = new Date(offre.date_demarrage);

    // Validation de la durée de l'interview
    if (offre.duree_interview <= 0 || isNaN(offre.duree_interview)) {
      setError("La durée de l'interview doit être un nombre positif.");
      return;
    }

    // Validation de la date d'entretien par rapport à la date de publication
    if (entDate <= pubDate) {
      setError("La date d'entretien doit être supérieure à la date de publication.");
      return;
    }

    // Validation de la date de démarrage par rapport à la date d'entretien
    if (startDate <= entDate) {
      setError("La date de démarrage doit être supérieure à la date d'entretien.");
      return;
    }

    // Validation du salaire
    if (offre.salaire <= 1) {
      setError("Le salaire doit être supérieur à 1.");
      return;
    }

    try {
      if (!offre.offre_id) {
        throw new Error("L'ID de l'offre est invalide.");
      }

      const offreRef = doc(db, "offres_emploi", offre.offre_id);
      await setDoc(offreRef, {
        ...offre,
        datePublication: pubDate,
        dateEntretien: entDate,
        date_demarrage: startDate, // Enregistrer la date de démarrage
        duree_interview: offre.duree_interview,
        salaire: offre.salaire, // Enregistrer le salaire
        ownerid: currentUser.email,
      });
      onSave();
    } catch (error) {
      console.error("Erreur lors de l'enregistrement de l'offre :", error);
      setError("Erreur lors de l'enregistrement de l'offre.");
    }
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        maxWidth: 800,
        margin: "auto",
        padding: 4,
        borderRadius: 3,
        boxShadow: 3,
        bgcolor: "background.paper",
      }}
      onSubmit={handleSubmit}
    >
      <Typography variant="h5" gutterBottom>
        {selectedOffre ? "Modifier l'offre" : "Ajouter une offre"}
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Titre"
            name="titre"
            value={offre.titre}
            onChange={handleChange}
            required
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth required>
            <InputLabel>Type</InputLabel>
            <Select
              name="type"
              value={offre.type}
              onChange={handleChange}
              variant="outlined"
            >
              <MenuItem value="Temps Plein">Temps Plein</MenuItem>
              <MenuItem value="Temps Partiel">Temps Partiel</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Lieu"
            name="lieu"
            value={offre.lieu}
            onChange={handleChange}
            required
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Date de Publication"
            name="datePublication"
            type="date"
            value={offre.datePublication}
            onChange={handleChange}
            required
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Date d'Entretien"
            name="dateEntretien"
            type="date"
            value={offre.dateEntretien}
            onChange={handleChange}
            required
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Durée de l'Interview (en minutes)"
            name="duree_interview"
            type="number"
            value={offre.duree_interview}
            onChange={handleChange}
            required
            inputProps={{ min: 1 }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormLabel component="legend">Catégorie</FormLabel>
          <RadioGroup
            row
            aria-label="categorie"
            name="categorie"
            value={offre.categorie}
            onChange={handleChange}
          >
            <FormControlLabel value="télé travail" control={<Radio />} label="Télé travail" />
            <FormControlLabel value="sur site" control={<Radio />} label="Sur site" />
          </RadioGroup>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Date de Démarrage"
            name="date_demarrage"
            type="date"
            value={offre.date_demarrage}
            onChange={handleChange}
            required
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Salaire"
            name="salaire"
            type="number"
            value={offre.salaire}
            onChange={handleChange}
            required
            inputProps={{ min: 1 }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Description"
            name="description"
            value={offre.description}
            onChange={handleChange}
            required
            multiline
            rows={4}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={offre.active}
                onChange={handleChange}
                name="active"
                color="primary"
              />
            }
            label="Offre Active"
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
        <Button variant="contained" color="primary" type="submit">
          {selectedOffre ? "Mettre à jour" : "Ajouter"}
        </Button>
        <Button variant="outlined" color="secondary" onClick={onCancel}>
          Annuler
        </Button>
      </Box>
      {error && (
        <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={() => setError("")}>
          <Alert onClose={() => setError("")} severity="error">
            {error}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
}

export default OffreForm;
