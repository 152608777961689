import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Paper,
  Grid,
  Button,
  Avatar,
  Divider,
  Container,
  Card,
  CardContent,
  Link as MuiLink,
  Alert,
  Stack
} from "@mui/material";
import { Link } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useAuth } from "../authContext";
import { db } from "../firebaseConfig";
import {
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import "react-circular-progressbar/dist/styles.css";
import EventNoteIcon from "@mui/icons-material/EventNote";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import AssessmentIcon from "@mui/icons-material/Assessment";
import QuizIcon from "@mui/icons-material/Quiz";
import { styled } from "@mui/system";
import DashboardIcon from '@mui/icons-material/Dashboard';
import WorkIcon from '@mui/icons-material/Work';
import ReportIcon from '@mui/icons-material/Report';
import { fetchUserSubscriptions } from "../fetchUserSubscriptions";

const RootContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const UserCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

function Dashboard() {
  const { currentUser } = useAuth();
  const [subscriptionInfo, setSubscriptionInfo] = useState({
    endDate: null,
    plan: "",
  });
  const [totalInterviews, setTotalInterviews] = useState(0);
  const [successfulSimulations, setSuccessfulSimulations] = useState(0);
  const [overallPerformance, setOverallPerformance] = useState(0);
  const [quizStats, setQuizStats] = useState({
    totalQuizzes: 0,
    highestScore: 0,
    lowestScore: 0,
    averageScore: 0,
  });
  const [jobOffers, setJobOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSimulationData = async () => {
      try {
        const simulationsCollection = collection(db, "simulations");

        const totalQuery = query(
          simulationsCollection,
          where("userid", "==", currentUser.email),
          where("status", "==", "Terminé")
        );
        const totalSnapshot = await getDocs(totalQuery);
        const totalSimulations = totalSnapshot.size;

        const successQuery = query(
          simulationsCollection,
          where("userid", "==", currentUser.email),
          where("status", "==", "Terminé"),
          where("Resultat", "==", "Réussi")
        );
        const successSnapshot = await getDocs(successQuery);
        const successfulSimulations = successSnapshot.size;

        const overallPerformance =
          totalSimulations > 0
            ? Math.round((successfulSimulations / totalSimulations) * 100)
            : 0;

        setTotalInterviews(totalSimulations);
        setSuccessfulSimulations(successfulSimulations);
        setOverallPerformance(overallPerformance);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données de simulation :",
          error
        );
      }
    };

    if (currentUser) {
      fetchSimulationData();
    }
  }, [currentUser]);

  useEffect(() => {
    const fetchQuizData = async () => {
      try {
        const quizCollection = collection(db, "quiztest");

        const quizQuery = query(
          quizCollection,
          where("userid", "==", currentUser.email),
          where("status", "==", "Terminé")
        );
        const quizSnapshot = await getDocs(quizQuery);

        const totalQuizzes = quizSnapshot.size;
        let highestScore = 0;
        let lowestScore = 100;
        let totalScore = 0;

        quizSnapshot.forEach((doc) => {
          const score = doc.data().score || 0;
          highestScore = Math.max(highestScore, score);
          lowestScore = Math.min(lowestScore, score);
          totalScore += score;
        });

        const averageScore = totalQuizzes > 0 ? totalScore / totalQuizzes : 0;

        setQuizStats({
          totalQuizzes,
          highestScore,
          lowestScore,
          averageScore,
        });
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données de quiz :",
          error
        );
      }
    };

    if (currentUser) {
      fetchQuizData();
    }
  }, [currentUser]);

  useEffect(() => {
    const fetchSubscriptionInfo = async () => {
      try {
        const subscriptions = await fetchUserSubscriptions(setSubscriptionInfo);
        if (subscriptions.length > 0) {
          const latestSubscription = subscriptions[0];
          setSubscriptionInfo({
            plan: latestSubscription.name,
            status: latestSubscription.status,
          });
        }
        setLoading(false);
      } catch (err) {
        console.error("Error fetching subscription info:", err);
        setError("Une erreur s'est produite lors de la récupération des informations d'abonnement.");
        setLoading(false);
      }
    };

    if (currentUser) {
      fetchSubscriptionInfo();
    }
  }, [currentUser]);

  useEffect(() => {
    const fetchJobOffers = async () => {
      try {
        const jobOffersCollection = collection(db, "offres_emploi");
        const jobOffersSnapshot = await getDocs(jobOffersCollection);
        const jobOffersList = jobOffersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setJobOffers(jobOffersList);
      } catch (error) {
        console.error("Erreur lors de la récupération des offres d'emploi :", error);
      }
    };

    fetchJobOffers();
  }, []);

  const showInviteMessage = quizStats.totalQuizzes + totalInterviews < 3 && !subscriptionInfo.plan;

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Tableau de Bord
      </Typography>
      <Typography variant="body1" gutterBottom>
        Bienvenue sur le Tableau de Bord ! Ici, vous pouvez voir vos métriques
        de performance, les activités récentes, et plus encore.
      </Typography>

      {showInviteMessage && (
        <Alert severity="info" sx={{ mb: 3 }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography>
              Découvrez nos évaluations de connaissances et simulations d'entretiens pour améliorer vos compétences. Profitez-en!
            </Typography>
            <Button variant="contained" color="primary" component={Link} to="/preparation">
              Évaluations
            </Button>
            <Button variant="contained" color="secondary" component={Link} to="/simulations">
              Simulations
            </Button>
          </Stack>
        </Alert>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
              backgroundColor: "#e3f2fd",
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: 6,
              },
            }}
            elevation={3}
          >
            <Avatar sx={{ bgcolor: "primary.main", mb: 2 }}>
              <EventNoteIcon fontSize="large" />
            </Avatar>
            <Typography variant="h6" color="primary">
              Entretiens Totals
            </Typography>
            <Typography variant="h4">{totalInterviews}</Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={3}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
              backgroundColor: "#e3f2fd",
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: 6,
              },
            }}
            elevation={3}
          >
            <Avatar sx={{ bgcolor: "primary.main", mb: 2 }}>
              <ThumbUpIcon fontSize="large" />
            </Avatar>
            <Typography variant="h6" color="primary">
              Simulations Réussies
            </Typography>
            <Typography variant="h4">{successfulSimulations}</Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={3}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
              backgroundColor: "#e8eaf6",
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: 6,
              },
            }}
            elevation={3}
          >
            <Avatar sx={{ bgcolor: "primary.main", mb: 2 }}>
              <QuizIcon fontSize="large" />
            </Avatar>
            <Typography variant="h6" color="primary">
              Quiz Totals
            </Typography>
            <Typography variant="h4">{quizStats.totalQuizzes}</Typography>
            <Typography variant="body2">
              Moyenne: {quizStats.averageScore.toFixed(2)}
            </Typography>
            <Typography variant="body2">
              Max: {quizStats.highestScore}
            </Typography>
            <Typography variant="body2">
              Min: {quizStats.lowestScore}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={3}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
              backgroundColor: "#e8eaf6",
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: 6,
              },
            }}
            elevation={3}
          >
            <Avatar sx={{ bgcolor: "primary.main", mb: 2 }}>
              <AssessmentIcon fontSize="large" />
            </Avatar>
            <Typography variant="h6" color="primary">
              Performance Globale
            </Typography>
            <CircularProgressbar
              value={overallPerformance}
              text={`${overallPerformance}%`}
              styles={buildStyles({
                pathColor: "#3f51b5",
                textColor: "#3f51b5",
                trailColor: "#cfd8dc",
              })}
            />
          </Paper>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 3, mb: 3 }} />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h5" gutterBottom>
              Interviews à Passer
            </Typography>
            {jobOffers.length > 0 ? (
              <ul>
                {jobOffers
                  .filter((offer) =>
                    offer.active === true &&
                    Array.isArray(offer.l_candidats) &&
                    offer.l_candidats.some(candidat =>
                      candidat.email === currentUser.email &&
                      candidat.interview_status === "Planned" &&
                      candidat.confirmed === true
                    ) &&
                    Array.isArray(offer.l_interview) &&
                    offer.l_interview.length > 0
                  )
                  .map((offer) => (
                    <li key={offer.id}>
                      <MuiLink
                        component={Link}
                        to={`/InterviewCandidat/${offer.id}`}
                        sx={{ textDecoration: 'underline', color: 'primary.main' }}
                      >
                        {offer.titre}
                      </MuiLink>
                    </li>
                  ))}
              </ul>
            ) : (
              <Typography variant="body1">
                Aucune interview à passer pour le moment.
              </Typography>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <UserCard>
            <CardContent>
              <Typography variant="h6">Informations d'Abonnement</Typography>
              {subscriptionInfo.plan ? (
                <>
                  <Typography variant="body1">
                    Plan : {subscriptionInfo.plan}
                  </Typography>
                  <Typography variant="body1">
                    Statut : {subscriptionInfo.status}
                  </Typography>
                </>
              ) : (
                <Typography variant="body1">
                  Vous n'avez pas d'abonnement actif.
                </Typography>
              )}
            </CardContent>
          </UserCard>
        </Grid>

        {subscriptionInfo.plan === "Entreprise" && (
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h5" gutterBottom>
                Entreprise
              </Typography>
              <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mb: 3 }}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<DashboardIcon />}
                  sx={{ mr: 2 }}
                  component={Link}
                  to="/DashboardEntreprise"
                >
                  Dashboard
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<WorkIcon />}
                  sx={{ mr: 2 }}
                  component={Link}
                  to="/OffreEmploi"
                >
                  Offre Emploi
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<ReportIcon />}
                  component={Link}
                  to="/InterviewReport"
                >
                  Rapport Interview
                </Button>
              </Box>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default Dashboard;
