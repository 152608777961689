import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Grid,
  CircularProgress,
  Chip,
  Collapse,
  Snackbar,
  Alert,
  TextField,
  MenuItem,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { collection, query, where, getDocs, doc, updateDoc, getDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { useAuth } from "../authContext";

// Helper function to format salary
const formatSalary = (salary) => {
  if (!salary || salary <= 0) return null; // Do not display if no salary specified or invalid

  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
  }).format(salary);
};

// Helper function to format date
const formatDate = (date) => {
  if (!date) return "";
  
  let formattedDate;
  if (typeof date === "string") {
    formattedDate = new Date(date);
  } else if (date.toDate) {
    formattedDate = date.toDate();
  } else {
    formattedDate = new Date(date);
  }

  return isNaN(formattedDate.getTime()) 
    ? "" 
    : formattedDate.toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
};

const OffrePublication = () => {
  const [offres, setOffres] = useState([]);
  const [filteredOffres, setFilteredOffres] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [expanded, setExpanded] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [searchCriteria, setSearchCriteria] = useState({
    titre: "",
    type: "",
    lieu: "",
  });
  const { currentUser } = useAuth();

  // Nouvel état pour stocker les offres auxquelles l'utilisateur a postulé
  const [appliedOffers, setAppliedOffers] = useState([]);

  const jobTypes = ["Temps Plein", "Temps Partiel", "Freelance", "Stage"]; // Example job types

  useEffect(() => {
    fetchActiveOffres();
  }, []);

  useEffect(() => {
    if (currentUser && offres.length > 0) {
      checkAppliedOffers();
    }
  }, [currentUser, offres]);

  // Récupération des offres actives
  const fetchActiveOffres = async () => {
    try {
      const offresCollection = collection(db, "offres_emploi");
      const activeOffresQuery = query(offresCollection, where("active", "==", true));
      const activeOffresSnapshot = await getDocs(activeOffresQuery);

      const offresList = activeOffresSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setOffres(offresList);
      setFilteredOffres(offresList); // Initialize the filtered offers
      setLoading(false);
    } catch (error) {
      console.error("Erreur lors de la récupération des offres :", error);
      setError("Erreur lors de la récupération des offres.");
      setLoading(false);
    }
  };

  // Vérification si l'utilisateur a déjà postulé par email
  const checkAppliedOffers = async () => {
    try {
      const appliedOfferIds = [];

      for (const offre of offres) {
        const offreDocRef = doc(db, "offres_emploi", offre.id);
        const offreDocSnapshot = await getDoc(offreDocRef);

        if (offreDocSnapshot.exists()) {
          const offreData = offreDocSnapshot.data();
          const lCandidats = offreData.l_candidats || [];

          // Vérifier si l'utilisateur a déjà postulé en comparant les emails
          const alreadyApplied = lCandidats.some(
            (candidate) => candidate.email === currentUser.email
          );

          if (alreadyApplied) {
            appliedOfferIds.push(offre.id); // Ajouter l'ID de l'offre si déjà postulé
          }
        }
      }

      setAppliedOffers(appliedOfferIds); // Mettre à jour les offres postulées
    } catch (error) {
      console.error("Erreur lors de la vérification des candidatures :", error);
    }
  };

  const handleExpandClick = (offreId) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [offreId]: !prevExpanded[offreId],
    }));
  };

  const handleApplyClick = async (offreId) => {
    if (!currentUser) {
      console.error("Utilisateur non connecté");
      return;
    }

    try {
      const offreDocRef = doc(db, "offres_emploi", offreId);
      const offreDocSnapshot = await getDoc(offreDocRef);

      if (offreDocSnapshot.exists()) {
        const offreData = offreDocSnapshot.data();
        const lCandidats = offreData.l_candidats || [];

        // Vérifier par email si l'utilisateur a déjà postulé
        const alreadyApplied = lCandidats.some((candidate) => candidate.email === currentUser.email);

        if (alreadyApplied) {
          setSnackbarMessage("Votre candidature existe déjà pour cette offre.");
          setSnackbarSeverity("warning");
          setOpenSnackbar(true);
          return;
        }

        await updateDoc(offreDocRef, {
          l_candidats: arrayUnion({
            userID: currentUser.uid,
            name: currentUser.displayName || "Nom inconnu",
            email: currentUser.email || "Email inconnu",
            interviewnote: 0,
            interviewcomment: "",
            interview_done: false,
            interview_status: "Planned",
            confirmed: false,
            source: "postulant",
          }),
        });

        setSnackbarMessage("Votre candidature a été envoyée avec succès !");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);

        setOffres((prevOffres) =>
          prevOffres.map((offre) =>
            offre.id === offreId
              ? {
                  ...offre,
                  l_candidats: [
                    ...offre.l_candidats,
                    {
                      userID: currentUser.uid,
                      name: currentUser.displayName || "Nom inconnu",
                      email: currentUser.email || "Email inconnu",
                      interviewnote: 0,
                      interviewcomment: "",
                      interview_done: false,
                      interview_status: "Planned",
                      confirmed: false,
                      source: "postulant",
                    },
                  ],
                }
              : offre
          )
        );

        // Ajouter l'offre à la liste des offres postulées
        setAppliedOffers((prev) => [...prev, offreId]);
      }
    } catch (error) {
      console.error("Erreur lors de la soumission de la candidature :", error);
      setSnackbarMessage("Une erreur est survenue lors de l'envoi de la candidature.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Handle search criteria change
  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchCriteria((prevCriteria) => ({ ...prevCriteria, [name]: value }));
  };

  // Filter offers based on search criteria
  const filterOffers = () => {
    const filtered = offres.filter((offre) => {
      const titreMatch = offre.titre.toLowerCase().includes(searchCriteria.titre.toLowerCase());
      const typeMatch = searchCriteria.type ? offre.type === searchCriteria.type : true;
      const lieuMatch = offre.lieu.toLowerCase().includes(searchCriteria.lieu.toLowerCase());

      return titreMatch && typeMatch && lieuMatch;
    });
    setFilteredOffres(filtered);
  };

  useEffect(() => {
    filterOffers();
  }, [searchCriteria]);

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh" }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom sx={{ textAlign: "center", marginBottom: 4 }}>
        Offres d'emploi disponibles
      </Typography>

      {/* Search Fields */}
      <Box sx={{ marginBottom: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              label="Titre de l'offre"
              name="titre"
              value={searchCriteria.titre}
              onChange={handleSearchChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              select
              label="Type d'emploi"
              name="type"
              value={searchCriteria.type}
              onChange={handleSearchChange}
              fullWidth
            >
              <MenuItem value="">Tous</MenuItem>
              {jobTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Lieu"
              name="lieu"
              value={searchCriteria.lieu}
              onChange={handleSearchChange}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={4}>
        {filteredOffres.length === 0 ? (
          <Typography variant="h6" sx={{ textAlign: "center", width: "100%" }}>
            Aucune offre disponible selon vos critères.
          </Typography>
        ) : (
          filteredOffres.map((offre) => {
            const alreadyApplied = appliedOffers.includes(offre.id); // Vérification si déjà postulé

            return (
              <Grid item xs={12} md={6} lg={4} key={offre.id}>
                <Card
                  sx={{
                    boxShadow: 3,
                    borderRadius: 2,
                    transition: "transform 0.2s",
                    "&:hover": { transform: "scale(1.02)" },
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                      {offre.titre}
                    </Typography>
                    <Chip
                      label={offre.categorie === "télé travail" ? "Télétravail" : "Sur site"}
                      color={offre.categorie === "télé travail" ? "primary" : "secondary"}
                      sx={{ marginBottom: 2 }}
                    />
                    <Typography variant="body2" color="text.secondary">
                      <strong>Type :</strong> {offre.type}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <strong>Lieu :</strong> {offre.lieu}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <strong>Date de démarrage :</strong> {formatDate(offre.date_demarrage)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <strong>Salaire :</strong> {formatSalary(offre.salaire)}
                    </Typography>

                    {/* Affichage de la coche verte si déjà postulé */}
                    {alreadyApplied && <CheckCircleIcon color="success" sx={{ fontSize: 40, marginTop: 2 }} />}

                    <Collapse in={expanded[offre.id]} timeout="auto" unmountOnExit>
                      <Typography variant="body2" color="text.secondary" sx={{ marginTop: 2 }}>
                        {offre.description}
                      </Typography>
                    </Collapse>
                  </CardContent>
                  <CardActions sx={{ justifyContent: "center", flexDirection: "column" }}>
                    {/* Masquer le bouton Postuler si déjà postulé */}
                    {!alreadyApplied && (
                      <Button
                        size="large"
                        color="primary"
                        variant="contained"
                        sx={{
                          borderRadius: 2,
                          textTransform: "none",
                          fontWeight: "bold",
                          marginBottom: 1,
                        }}
                        onClick={() => handleApplyClick(offre.id)}
                      >
                        Postuler
                      </Button>
                    )}
                    <Button
                      size="small"
                      color="secondary"
                      onClick={() => handleExpandClick(offre.id)}
                      sx={{
                        textTransform: "none",
                      }}
                    >
                      {expanded[offre.id] ? "Réduire la description" : "Voir la description"}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })
        )}
      </Grid>

      {/* Snackbar to show notifications */}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default OffrePublication;
