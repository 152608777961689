import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { db } from "../firebaseConfig";
import { collection, getDocs, doc, addDoc, onSnapshot,deleteDoc, } from "firebase/firestore";
import { useAuth } from "../authContext";
import { fetchUserSubscriptions } from "../fetchUserSubscriptions"; // Import de la fonction fetchUserSubscriptions

const RootContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: "center",
}));

const PlanCard = styled(Card)(({ theme }) => ({
  margin: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  minHeight: "350px", // Hauteur minimale définie pour uniformiser les cartes
}));

const FeaturesTable = styled(Table)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const SubscriptionPlans = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [plans, setPlans] = useState([]);
  const [currentPlan, setCurrentPlan] = useState("");
  const [loading, setLoading] = useState({}); // Etat de chargement pour chaque plan

  useEffect(() => {
    const fetchPlans = async () => {
      const plansCollection = collection(db, "products");
      const plansSnapshot = await getDocs(plansCollection);
      const plansList = [];

      for (const planDoc of plansSnapshot.docs) {
        const planData = planDoc.data();

        if (planData.active) {
          const pricesCollection = collection(planDoc.ref, "prices");
          const pricesSnapshot = await getDocs(pricesCollection);
          const prices = pricesSnapshot.docs.map((priceDoc) => priceDoc.data());

          plansList.push({
            ...planData,
            prices: prices,
            features: planData.description.split(".").map(desc => desc.trim()).filter(desc => desc), // Extraire les fonctionnalités
          });
        }
      }

      setPlans(plansList);
    };

    fetchPlans();
  }, []);

  useEffect(() => {
    const fetchCurrentPlan = async () => {
      if (currentUser) {
        try {
          const subscriptions = await fetchUserSubscriptions(() => {});
          if (subscriptions.length > 0) {
            setCurrentPlan(subscriptions[0].name || "");
          } else {
            setCurrentPlan("");
          }
        } catch (err) {
          console.error("Erreur lors de la récupération du plan d'abonnement:", err);
          setCurrentPlan("");
        }
      }
    };

    fetchCurrentPlan();
  }, [currentUser]);

  const handleSubscribe = async (priceId, planId) => {
    if (!priceId) {
      console.error("Invalid price ID");
      return;
    }

    setLoading((prev) => ({ ...prev, [planId]: true })); // Définir le chargement pour ce plan à vrai

    try {
      const userDocRef = doc(db, "Users", currentUser.uid);
      const checkoutSessionsCollectionRef = collection(userDocRef, "checkout_sessions");

      const docRef = await addDoc(checkoutSessionsCollectionRef, {
        price: priceId,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });

      onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data();
        if (error) {
          alert(`An error occurred: ${error.message}`);
        }
        if (url) {
          window.location.assign(url);
        }
      });
    } catch (error) {
      console.error("Error creating checkout session:", error);
    } finally {
      setLoading((prev) => ({ ...prev, [planId]: false })); // Définir le chargement pour ce plan à faux
    }
  };
 //----------------------- Debut Annulation abonnement -------------------------------

 const handleUnsubscribe = async () => {
  if (!currentUser) return;

  try {
    // Récupérer l'ID de l'abonnement actuel
    const userDocRef = doc(db, "Users", currentUser.uid);
    const subscriptionsCollectionRef = collection(userDocRef, "subscriptions");
    const subscriptionsSnapshot = await getDocs(subscriptionsCollectionRef);

    let currentSubscriptionId = null;
    let currentSubscriptionDocRef = null;

    subscriptionsSnapshot.forEach((subscriptionDoc) => {
      const subscriptionData = subscriptionDoc.data();
      const items = subscriptionData.items || [];
      if (items.length > 0) {
        currentSubscriptionId = items[0].subscription; // Récupérer le premier abonnement
        currentSubscriptionDocRef = subscriptionDoc.ref; // Conserver la référence au document d'abonnement
      }
    });

    if (!currentSubscriptionId) {
      console.error("Aucun abonnement trouvé pour l'utilisateur.");
      return;
    }

    const response = await fetch('http://localhost:4242/cancel-subscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ subscriptionId: currentSubscriptionId }),
    });

    const result = await response.json();

    if (result.success) {
      // Supprimer l'abonnement de Firestore
      await deleteDoc(currentSubscriptionDocRef);
      
      setCurrentPlan(""); // Réinitialisez le plan actuel
      alert("Votre abonnement annulé avec succès.");
    } else {
      console.error('Erreur lors de l\'annulation de l\'abonnement:', result.message);
      alert('Erreur lors de l\'annulation de l\'abonnement');
    }
  } catch (error) {
    console.error("Erreur lors de l'annulation de l'abonnement:", error);
    alert("Erreur lors de l'annulation de l'abonnement");
  }
};



  //----------------- Fin fonction annulation --------------------------------------


  // Récupérer toutes les fonctionnalités uniques de tous les plans
  const allFeatures = [
    ...new Set(plans.flatMap((plan) => plan.features)),
  ];

  return (
    <RootContainer>
      <Typography variant="h4" gutterBottom>
        Choisissez votre plan
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        {plans.map((plan) => (
          <Grid item xs={12} sm={6} md={4} key={plan.id}>
            <PlanCard>
              <CardContent style={{ flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography variant="h5" component="h2">
                  {plan.name}
                </Typography>
                {plan.prices.map((price, index) => (
                  <Typography variant="h6" color="textSecondary" key={index}>
                    {price.unit_amount / 100} {price.currency.toUpperCase()} / {price.interval}
                  </Typography>
                ))}
                {plan.features.map((feature, index) => (
                  <Typography variant="body2" key={index}>
                    {feature}
                  </Typography>
                ))}
              </CardContent>
              <Box mt={2} mb={2} display="flex" flexDirection="column" width="100%">
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    const priceId = plan.prices[0]?.id;
                    if (priceId) {
                      handleSubscribe(priceId, plan.id);
                    } else {
                      console.error("Price ID is missing for plan:", plan.name);
                    }
                  }}
                  disabled={plan.name === currentPlan || loading[plan.id]}
                >
                  {loading[plan.id] ? (
                    <CircularProgress size={24} />
                  ) : plan.name === currentPlan ? (
                    "Plan Actuel"
                  ) : (
                    "Choisir ce plan"
                  )}
                </Button>
                {plan.name === currentPlan && (
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={handleUnsubscribe}
                    style={{ marginTop: "10px" }}
                  >
                    Annuler l'abonnement
                  </Button>
                )}
              </Box>
            </PlanCard>
          </Grid>
        ))}
      </Grid>
      <TableContainer component={Paper}>
        <FeaturesTable>
          <TableHead>
            <TableRow>
              <TableCell>Fonctionnalités</TableCell>
              {plans.map((plan) => (
                <TableCell key={plan.id} align="center">
                  {plan.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {allFeatures.map((feature, index) => (
              <TableRow key={index}>
                <TableCell>{feature}</TableCell>
                {plans.map((plan) => (
                  <TableCell key={plan.id} align="center">
                    {plan.features.includes(feature) ? "✔️" : "❌"}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </FeaturesTable>
      </TableContainer>
    </RootContainer>
  );
};

export default SubscriptionPlans;
