import React from "react";
import {
  Container,
  Button,
  Typography,
  Paper,
  Alert,
  Box,
  Grid,
  Card,
  CardContent,
  Avatar,
  Stack,
} from "@mui/material";
import { styled } from "@mui/system";
import GoogleIcon from "@mui/icons-material/Google";
import ProblemIcon from "@mui/icons-material/ReportProblem";
import SolutionIcon from "@mui/icons-material/EmojiObjects";
import TestimonialIcon from "@mui/icons-material/ThumbUp";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../authContext";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#f5f5f5",
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontWeight: "bold",
  fontSize: "1.5rem",
  color: "#333",
  marginBottom: theme.spacing(2),
}));

const SectionText = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  lineHeight: 1.6,
  color: "#555",
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

const Icon = styled(GoogleIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const testimonials = [
  {
    name: "Laruche",
    comment: "JobJourney m'a vraiment aidé à me préparer pour mes entretiens. Les simulations sont très réalistes!",
    avatar: "https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg",
  },
  {
    name: "Mareline Mayella",
    comment: "Grâce à JobJourney, j'ai pu identifier mes points faibles et m'améliorer rapidement.",
    avatar: "https://images.pexels.com/photos/1181686/pexels-photo-1181686.jpeg",
  },
  {
    name: "Konate",
    comment: "Les feedbacks personnalisés sont excellents. Je recommande vivement cette application!",
    avatar: "https://images.pexels.com/photos/936075/pexels-photo-936075.jpeg",
  },
  {
    name: "Chaboui Dieudonné",
    comment: "Les analyses détaillées m'ont aidé à comprendre où je pouvais m'améliorer.",
    avatar: "https://images.pexels.com/photos/1866149/pexels-photo-1866149.jpeg",
  },
  {
    name: "Aminata Diallo",
    comment: "Une application très intuitive qui m'a permis de me sentir prête pour mes entretiens.",
    avatar: "https://images.pexels.com/photos/1181519/pexels-photo-1181519.jpeg",
  },
  {
    name: "Cheikh Ndiaye",
    comment: "Le feedback reçu a été extrêmement utile pour mes entretiens réels.",
    avatar: "https://images.pexels.com/photos/2422434/pexels-photo-2422434.jpeg",
  },
];

const Connexion = () => {
  const navigate = useNavigate();
  const { signInWithGoogle } = useAuth();
  const [error, setError] = React.useState(null);

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
      navigate("/");
    } catch (error) {
      setError("Échec de la connexion avec Google.");
      console.error("Échec de la connexion avec Google", error);
    }
  };

  return (
    <Container component="main" maxWidth="md">
      {/* Message d'Accroche avec Image */}
      <Alert severity="info" sx={{ mt: 3, mb: 3 }}>
        <Stack direction="column" alignItems="center" spacing={2}>
          <Box
            component="img"
            src="https://images.pexels.com/photos/3184292/pexels-photo-3184292.jpeg"
            alt="Invitation à tester JobJourney"
            sx={{
              width: 250,
              height: "auto",
              borderRadius: 1,
              mb: 2,
            }}
          />
          <Typography variant="h4" color="primary" align="center">
            Testez gratuitement vos compétences et passez des entretiens simulés avec notre IA !
          </Typography>
          <Typography variant="body1" align="center">
            Connectez-vous pour découvrir notre plateforme et préparez-vous avec des simulations d'entretiens
            personnalisées et des feedbacks en temps réel. Rejoignez-nous pour un accompagnement complet vers votre
            succès professionnel.
          </Typography>
          <StyledButton variant="contained" color="primary" onClick={handleGoogleSignIn}>
            <Icon />
            Se Connecter avec Google
          </StyledButton>
        </Stack>
      </Alert>

      <Grid container spacing={3}>
        {/* Cadran de Connexion */}
        <Grid item xs={12}>
          <StyledPaper>
            <Typography component="h1" variant="h5">
              Connexion
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
            <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
              <StyledButton
                variant="outlined"
                color="primary"
                onClick={handleGoogleSignIn}
              >
                <Icon />
                Google
              </StyledButton>
            </Box>
          </StyledPaper>
        </Grid>

        {/* Cadran du Défi Rencontré */}
        <Grid item xs={12}>
          <StyledPaper>
            <SectionTitle>
              <ProblemIcon style={{ color: "#f44336", marginRight: "0.5rem" }} />
              Le Défi Rencontré
            </SectionTitle>
            <SectionText>
              Beaucoup de candidats se sentent mal préparés pour leurs entretiens. Le stress, l'incertitude
              sur les questions posées et l'absence de feedback rendent la préparation difficile. Cette situation
              peut entraîner une perte de confiance et réduire les chances de réussite.
            </SectionText>
          </StyledPaper>
        </Grid>

        {/* Cadran de la Solution Innovante */}
        <Grid item xs={12}>
          <StyledPaper>
            <SectionTitle>
              <SolutionIcon style={{ color: "#4caf50", marginRight: "0.5rem" }} />
              La Solution Innovante de JobJourney
            </SectionTitle>
            <SectionText>
              JobJourney transforme votre préparation aux entretiens en une expérience positive et enrichissante. 
              Avec des simulations réalistes, un feedback personnalisé et des analyses détaillées, vous gagnez en 
              confiance et en compétences pour aborder vos entretiens avec succès.
            </SectionText>
          </StyledPaper>
        </Grid>

        {/* Cadran des Témoignages Clients */}
        <Grid item xs={12}>
          <StyledPaper>
            <SectionTitle>
              <TestimonialIcon style={{ color: "#2196f3", marginRight: "0.5rem" }} />
              Nos Abonnés Témoignent
            </SectionTitle>
            <Grid container spacing={2}>
              {testimonials.map((testimonial, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <Avatar src={testimonial.avatar} alt={testimonial.name} />
                        </Grid>
                        <Grid item xs>
                          <Typography variant="h6">{testimonial.name}</Typography>
                          <Typography variant="body2" color="textSecondary">
                            {testimonial.comment}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </StyledPaper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Connexion;
