import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Paper, CircularProgress, Avatar } from "@mui/material";
import { useAuth } from "../authContext";
import { db } from "../firebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore";
import WorkIcon from "@mui/icons-material/Work";
import PeopleIcon from "@mui/icons-material/People";
import EventNoteIcon from "@mui/icons-material/EventNote";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AccessTimeIcon from "@mui/icons-material/AccessTime"; // Icon for average duration
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

// Register Chart.js components and plugins
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

function DashboardEntreprise() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [totalOffres, setTotalOffres] = useState(0);
  const [activeOffres, setActiveOffres] = useState(0);
  const [totalCandidats, setTotalCandidats] = useState(0);
  const [confirmedInterviews, setConfirmedInterviews] = useState(0);
  const [totalInterviewsPassed, setTotalInterviewsPassed] = useState(0);
  const [overallSuccessRate, setOverallSuccessRate] = useState(0);
  const [averageInterviewDuration, setAverageInterviewDuration] = useState(0); // New state for average interview duration

  useEffect(() => {
    const fetchKPIData = async () => {
      setLoading(true);
      try {
        const offresCollection = collection(db, "offres_emploi");
        const offresQuery = query(
          offresCollection,
          where("ownerid", "==", currentUser.email)
        );
        const offresSnapshot = await getDocs(offresQuery);
        const offresList = offresSnapshot.docs.map((doc) => doc.data());
        setTotalOffres(offresList.length);
        setActiveOffres(
          offresList.filter((offre) => offre.active !== false).length
        );

        let totalCandidatsCount = 0;
        let confirmedInterviewsCount = 0;
        let totalSuccessCount = 0;
        let totalInterviewCount = 0;
        let interviewsPassedCount = 0;
        let totalInterviewDuration = 0; // Variable for total interview duration
        let interviewCountWithDuration = 0; // Variable for interviews with a duration

        offresList.forEach((offre) => {
          if (offre.l_candidats) {
            totalCandidatsCount += offre.l_candidats.length;
            confirmedInterviewsCount += offre.l_candidats.filter(
              (candidat) => candidat.confirmed === true
            ).length;

            offre.l_candidats.forEach((candidat) => {
              // Filter candidates who have passed the interview
              if (
                candidat.interviewnote > 50 &&
                candidat.interview_status !== "Planned" &&
                candidat.interviewnote !== 0
              ) {
                totalSuccessCount += 1;
              }
              if (
                candidat.interviewnote !== undefined &&
                candidat.interview_status !== "Planned" &&
                candidat.interviewnote !== 0
              ) {
                totalInterviewCount += 1;
              }

              // Calculate total interview duration
              if (candidat.duree_interview_passee) {
                totalInterviewDuration += candidat.duree_interview_passee;
                interviewCountWithDuration += 1;
              }
            });
          }
          if (Array.isArray(offre.interview_session) && offre.interview_session.length > 0) {
            interviewsPassedCount += 1;
          }
        });

        setTotalCandidats(totalCandidatsCount);
        setConfirmedInterviews(confirmedInterviewsCount);
        setTotalInterviewsPassed(interviewsPassedCount);
        setOverallSuccessRate(
          totalInterviewCount > 0
            ? (totalSuccessCount / totalInterviewCount) * 100
            : 0
        );
        setAverageInterviewDuration(
          interviewCountWithDuration > 0
            ? totalInterviewDuration / interviewCountWithDuration
            : 0
        ); // Set average interview duration
      } catch (error) {
        console.error("Erreur lors de la récupération des données KPI :", error);
      } finally {
        setLoading(false);
      }
    };

    if (currentUser) {
      fetchKPIData();
    }
  }, [currentUser]);

  // Données pour le graphique donut
  // Exemple de structure de données conditionnelle pour le graphique
const calculateDonutData = (interviews) => {
  if (interviews.length === 0) {
    // Si aucune interview n'a été passée, afficher un graphique en gris
    return {
      labels: ['Aucune interview'],
      datasets: [
        {
          data: [100],
          backgroundColor: ['#b0bec5'], // Couleur grise
          borderWidth: 1,
        },
      ],
    };
  } else {
    // Calculer le taux de réussite global si des interviews existent
    const totalInterviews = interviews.length;
    const totalSuccesses = interviews.filter((interview) => interview.success).length;
    const successRate = (totalSuccesses / totalInterviews) * 100;
    return {
      labels: ['Réussite', 'Échec'],
      datasets: [
        {
          data: [successRate, 100 - successRate],
          backgroundColor: ['#4caf50', '#f44336'], // Vert pour réussite, rouge pour échec
          borderWidth: 1,
        },
      ],
    };
  }
};

// Exemple d'utilisation avec des données fictives
const interviews = []; // Aucune interview passée
const donutData = calculateDonutData(interviews);


  // Options pour afficher les pourcentages dans le graphique donut
  const donutOptions = {
    plugins: {
      datalabels: {
        formatter: (value, context) => {
          return value.toFixed(2) + "%"; // Formatte les valeurs en pourcentage
        },
        color: "#fff",
        font: {
          size: 14,
          weight: 'bold',
        },
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Tableau de Bord Entreprise
      </Typography>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#e3f2fd",
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: 6,
                },
              }}
              elevation={3}
            >
              <Avatar sx={{ bgcolor: "primary.main", mb: 2 }}>
                <WorkIcon fontSize="large" />
              </Avatar>
              <Typography variant="h6" color="primary">
                Offres d'Emploi Totales
              </Typography>
              <Typography variant="h4">{totalOffres}</Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#e8eaf6",
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: 6,
                },
              }}
              elevation={3}
            >
              <Avatar sx={{ bgcolor: "primary.main", mb: 2 }}>
                <EventNoteIcon fontSize="large" />
              </Avatar>
              <Typography variant="h6" color="primary">
                Offres Actives
              </Typography>
              <Typography variant="h4">{activeOffres}</Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#e3f2fd",
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: 6,
                },
              }}
              elevation={3}
            >
              <Avatar sx={{ bgcolor: "primary.main", mb: 2 }}>
                <PeopleIcon fontSize="large" />
              </Avatar>
              <Typography variant="h6" color="primary">
                Total des Candidats
              </Typography>
              <Typography variant="h4">{totalCandidats}</Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#e8eaf6",
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: 6,
                },
              }}
              elevation={3}
            >
              <Avatar sx={{ bgcolor: "primary.main", mb: 2 }}>
                <ThumbUpIcon fontSize="large" />
              </Avatar>
              <Typography variant="h6" color="primary">
                Interviews Confirmées
              </Typography>
              <Typography variant="h4">{confirmedInterviews}</Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#e3f2fd",
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: 6,
                },
              }}
              elevation={3}
            >
              <Avatar sx={{ bgcolor: "primary.main", mb: 2 }}>
                <AssignmentIcon fontSize="large" />
              </Avatar>
              <Typography variant="h6" color="primary">
                Interviews Passées
              </Typography>
              <Typography variant="h4">{totalInterviewsPassed}</Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#e8eaf6",
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: 6,
                },
              }}
              elevation={3}
            >
              <Avatar sx={{ bgcolor: "primary.main", mb: 2 }}>
                <AccessTimeIcon fontSize="large" />
              </Avatar>
              <Typography variant="h6" color="primary">
                Durée Moyenne des Interviews (min)
              </Typography>
              <Typography variant="h4">
                {averageInterviewDuration.toFixed(2)} {/* Display average interview duration */}
              </Typography>
            </Paper>
          </Grid>
          
          

          
          <Grid item xs={12} md={4}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#e8eaf6",
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: 6,
                },
              }}
              elevation={3}
            >
              <Avatar sx={{ bgcolor: "primary.main", mb: 2 }}>
                <AssessmentIcon fontSize="large" />
              </Avatar>
              <Typography variant="h6" color="primary" gutterBottom>
                Taux de Réussite Global
              </Typography>
              <Box sx={{ width: "80%", mx: "auto", height: 200 }}>
                <Doughnut data={donutData} options={donutOptions} />
              </Box>
            </Paper>
          </Grid>


          
        </Grid>
      )}
    </Box>
  );
}

export default DashboardEntreprise;
