import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  CircularProgress,
  Card,
  CardContent,
  CardActions,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
} from "@mui/material";
import BookIcon from "@mui/icons-material/Book";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import { useAuth } from "../authContext";
import { db } from "../firebaseConfig";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import {
  fetchOpenAiData,
  generateAndStoreGuides,
} from "../api/fetchOpenAiData";
import { useNavigate } from "react-router-dom";

const GuidesRessource = () => {
  const { currentUser } = useAuth();
  const [guides, setGuides] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkUserProfile = async () => {
      if (!currentUser) return;

      const userDoc = doc(db, "Users", currentUser.email);
      const userSnapshot = await getDoc(userDoc);
      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        if (
          !userData.skills ||
          !userData.interestSectors ||
          !userData.careerGoals
        ) {
          setOpenDialog(true);
          return false;
        }
      } else {
        setOpenDialog(true);
        return false;
      }
      return true;
    };

    const fetchGuides = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      try {
        const guidesCollection = collection(db, "guides");
        const q = query(
          guidesCollection,
          where("userId", "==", currentUser.email)
        );
        const querySnapshot = await getDocs(q);
        const guidesData = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            userId: data.userId,
            l_guide: data.l_guide,
          };
        });
        setGuides(guidesData);
      } catch (err) {
        if (err.message.includes("parsing JSON")) {
          setError(
            "La création des guides a été interrompue. Merci de tenter à nouveau."
          );
          navigate("/preparation");
        } else {
          setError(err.message);
        }
      } finally {
        setLoading(false);
      }
    };

    const initializeGuides = async () => {
      if (!currentUser) return;

      try {
        const guidesCollection = collection(db, "guides");
        const q = query(
          guidesCollection,
          where("userId", "==", currentUser.email)
        );
        const querySnapshot = await getDocs(q);
        const guidesCount = querySnapshot.size;

        if (guidesCount > 10) {
          fetchGuides();
          return;
        }

        const userData = await fetchOpenAiData(currentUser);
        await generateAndStoreGuides(userData, currentUser);
        fetchGuides();
      } catch (error) {
        if (error.message.includes("parsing JSON")) {
          setError(
            "La création des guides a été interrompue. Merci de tenter à nouveau."
          );
          navigate("/preparation");
        } else {
          setError(error.message);
        }
      }
    };

    const initializeComponent = async () => {
      const isUserProfileComplete = await checkUserProfile();
      if (isUserProfileComplete) {
        initializeGuides();
      } else {
        setLoading(false);
      }
    };

    initializeComponent();
  }, [currentUser]);

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "guides", id));
      setGuides((prevGuides) => prevGuides.filter((guide) => guide.id !== id));
    } catch (err) {
      console.error("Erreur lors de la suppression :", err.message);
    }
  };

  const handleCloseDialog = () => {
    navigate("/profile");
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress size={60} />
        <Typography variant="h6" sx={{ ml: 2 }}>
          Chargement des guides...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography color="error" variant="h6">
          Erreur de chargement : {error}
        </Typography>
      </Box>
    );
  }

  const cardColors = ["linear-gradient(to right, #FFCDD2, #E57373)", "linear-gradient(to right, #C8E6C9, #81C784)", "linear-gradient(to right, #BBDEFB, #64B5F6)"];

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
        <BookIcon sx={{ mr: 1 }} />
        <Typography variant="h4" gutterBottom>
          Guides et Ressources
        </Typography>
      </Box>
      <Alert
        severity="info"
        icon={<InfoIcon />}
        sx={{
          mb: 3,
          fontWeight: "bold",
          fontSize: "1rem",
          border: "1px solid #2196f3",
          backgroundColor: "#e3f2fd",
          color: "#0d47a1",
        }}
      >
        <Typography>
          <strong>Vos guides et ressources</strong> sont mis à jour
          automatiquement en fonction de votre profil. Vous pouvez{" "}
          <strong>supprimer une ressource non pertinente</strong> afin d'en
          générer une autre.
        </Typography>
      </Alert>
      {guides.length > 0 ? (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
            gap: 3,
          }}
        >
          {guides.map((guide, index) => (
            <Card
              key={guide.id}
              sx={{
                mb: 2,
                background: cardColors[index % cardColors.length],
                color: "#fff",
                borderRadius: "16px",
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0 6px 30px rgba(0, 0, 0, 0.3)",
                },
              }}
              elevation={3}
            >
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6">
                    {guide.l_guide[0]?.title || "Guide"}
                  </Typography>
                  <IconButton
                    color="error"
                    onClick={() => handleDelete(guide.id)}
                  >
                    <DeleteIcon sx={{ color: "#fff" }} />
                  </IconButton>
                </Box>
                {guide.l_guide.slice(0, 3).map((item, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <Typography variant="body2">{item.description}</Typography>
                  </Box>
                ))}
              </CardContent>
              {guide.link && (
                <CardActions>
                  <Button
                    size="small"
                    sx={{
                      color: "#fff",
                      border: "1px solid rgba(255, 255, 255, 0.8)",
                      "&:hover": {
                        border: "1px solid #fff",
                      },
                    }}
                    href={guide.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Lire plus
                  </Button>
                </CardActions>
              )}
            </Card>
          ))}
        </Box>
      ) : (
        <Typography variant="body1" align="center">
          Aucun guide trouvé.
        </Typography>
      )}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Profil Incomplet"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Veuillez renseigner vos informations de carrière dans le menu
            profil.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default GuidesRessource;
