import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Box,
  CircularProgress,
} from "@mui/material";
import { styled } from '@mui/system';

// Stylisation des cartes d'abonnement
const SubscriptionCard = styled(Card)({
  backgroundColor: "#f5f5f5",
  margin: "20px",
  borderRadius: "10px",
  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
});

const Abonnementss = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [summary, setSummary] = useState({});
  const [loadingSummary, setLoadingSummary] = useState(true); // Loading state for summary cards
  const [loadingTable, setLoadingTable] = useState(true); // Loading state for table

  useEffect(() => {
    const loadSubscriptions = async () => {
      try {
        const usersCollection = collection(db, "Users");
        const userSnapshot = await getDocs(usersCollection);
        const allSubscriptions = [];

        for (const userDoc of userSnapshot.docs) {
          const userData = userDoc.data();
          const subscriptionsCollection = collection(userDoc.ref, "subscriptions");
          const subscriptionsSnapshot = await getDocs(subscriptionsCollection);

          const userSubscriptions = subscriptionsSnapshot.docs.map((subscriptionDoc) => {
            const subscriptionData = subscriptionDoc.data();
            const startDate = new Date(subscriptionData.current_period_start * 1000);
            const endDate = new Date(subscriptionData.current_period_end * 1000);

            return {
              email: userData.email,
              name: subscriptionData.items[0]?.price.product.name || "Produit inconnu",
              status: subscriptionData.status,
              startDate,
              endDate,
            };
          });

          const activeSubscriptions = userSubscriptions.filter(sub => sub.status === "active");
          allSubscriptions.push(...activeSubscriptions);
        }

        setSubscriptions(allSubscriptions);

        const summaryData = allSubscriptions.reduce((acc, subscription) => {
          const { name } = subscription;
          if (!acc[name]) {
            acc[name] = 1;
          } else {
            acc[name]++;
          }
          return acc;
        }, {});
        setSummary(summaryData);

        setLoadingSummary(false); // Summary data loaded
        setLoadingTable(false); // Table data loaded
      } catch (err) {
        console.error("Erreur lors du chargement des abonnements :", err);
        setLoadingSummary(false); // Stop loading even on error
        setLoadingTable(false); // Stop loading even on error
      }
    };

    loadSubscriptions();
  }, []);

  return (
    <Box sx={{ padding: "20px", backgroundColor: "#f0f0f0" }}>
      {/* Section des cartes résumant les abonnements */}
      <Grid container spacing={3} justifyContent="center">
        {loadingSummary ? (
          // Show loading spinner for summary cards
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
            <CircularProgress />
          </Box>
        ) : (
          Object.keys(summary).map((plan) => (
            <Grid item xs={12} sm={6} md={4} key={plan}>
              <SubscriptionCard>
                <CardContent>
                  <Typography variant="h6" color="primary" gutterBottom>
                    {plan}
                  </Typography>
                  <Typography variant="h4" color="textSecondary">
                    {summary[plan]} 
                  </Typography>
                </CardContent>
              </SubscriptionCard>
            </Grid>
          ))
        )}
      </Grid>

      {/* Tableau des abonnements */}
      {loadingTable ? (
        // Show loading spinner for the table
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper} sx={{ marginTop: "20px", borderRadius: "10px", boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)" }}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#3f51b5" }}>
                <TableCell sx={{ color: "#fff" }}>Email</TableCell>
                <TableCell sx={{ color: "#fff" }}>Plan</TableCell>
                <TableCell sx={{ color: "#fff" }}>Status</TableCell>
                <TableCell sx={{ color: "#fff" }}>Date de début</TableCell>
                <TableCell sx={{ color: "#fff" }}>Date de fin</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptions.map((subscription, index) => (
                <TableRow key={index} sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" } }}>
                  <TableCell>{subscription.email}</TableCell>
                  <TableCell>{subscription.name}</TableCell>
                  <TableCell>{subscription.status}</TableCell>
                  <TableCell>{subscription.startDate.toLocaleDateString()}</TableCell>
                  <TableCell>{subscription.endDate.toLocaleDateString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default Abonnementss;
