import React, { useState, useEffect } from "react";
import { useAuth } from "../authContext";
import { db } from "../firebaseConfig";
import { doc, updateDoc, getDoc, Timestamp } from "firebase/firestore";
import {
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
  Divider,
  List,
  ListItem,
  IconButton,
  Tooltip,
  Autocomplete,
  Snackbar,
  Alert,
  Avatar,
} from "@mui/material";
import styled from "@emotion/styled";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { orange } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: orange[500],
    },
  },
});

const StyledPaper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(3)};
  margin-top: ${(props) => props.theme.spacing(3)};
  margin-bottom: ${(props) => props.theme.spacing(3)};
`;

const Section = styled.div`
  margin-bottom: ${(props) => props.theme.spacing(3)};
`;

const StyledButton = styled(Button)`
  margin-top: ${(props) => props.theme.spacing(2)};
`;

const ButtonSpacing = styled(Button)`
  margin-top: ${(props) => props.theme.spacing(2)};
  margin-left: ${(props) => props.theme.spacing(2)};
`;

const BottomDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${(props) => props.theme.spacing(3)};
`;

const Profile = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [profileInfo, setProfileInfo] = useState({
    careerGoals: "",
    email: "",
    name: "",
    phone: "",
    country: "",
    city: "",
  });

  const [experiences, setExperiences] = useState([
    { company: "", duration: "", position: "" },
  ]);

  const [interestSectors, setInterestSectors] = useState([""]);
  const [skills, setSkills] = useState([""]);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDoc = doc(db, "Users", currentUser.email);
        const userSnapshot = await getDoc(userDoc);

        if (userSnapshot.exists()) {
          const data = userSnapshot.data();
          setProfileInfo({
            careerGoals: data.careerGoals || "",
            email: data.email || "",
            name: data.name || "",
            phone: data.phone || "",
            country: data.country || "",
            city: data.city || "",
          });
          setExperiences(
            data.experiences || [{ company: "", duration: "", position: "" }]
          );
          setInterestSectors(data.interestSectors || [""]);
          setSkills(data.skills || [""]);
          console.log("User data fetched successfully", data);
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données utilisateur :",
          error
        );
      }
    };

    if (currentUser) {
      fetchUserData();
    }
  }, [currentUser]);

  const handleProfileChange = (e) => {
    setProfileInfo({
      ...profileInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleExperienceChange = (index, e) => {
    const newExperiences = [...experiences];
    newExperiences[index][e.target.name] = e.target.value;
    setExperiences(newExperiences);
  };

  const handleAddExperience = () => {
    setExperiences([
      ...experiences,
      { company: "", duration: "", position: "" },
    ]);
  };

  const handleRemoveExperience = (index) => {
    const newExperiences = experiences.filter((_, i) => i !== index);
    setExperiences(newExperiences);
  };

  const handleInterestSectorChange = (index, value) => {
    const newInterestSectors = [...interestSectors];
    newInterestSectors[index] = value;
    setInterestSectors(newInterestSectors);
  };

  const handleAddInterestSector = () => {
    setInterestSectors([...interestSectors, ""]);
  };

  const handleRemoveInterestSector = (index) => {
    const newInterestSectors = interestSectors.filter((_, i) => i !== index);
    setInterestSectors(newInterestSectors);
  };

  const handleSkillChange = (index, value) => {
    const newSkills = [...skills];
    newSkills[index] = value;
    setSkills(newSkills);
  };

  const handleAddSkill = () => {
    setSkills([...skills, ""]);
  };

  const handleRemoveSkill = (index) => {
    const newSkills = skills.filter((_, i) => i !== index);
    setSkills(newSkills);
  };

  const handleProfileSubmit = async () => {
    try {
      const userDoc = doc(db, "Users", currentUser.email);
      await updateDoc(userDoc, {
        ...profileInfo,
        experiences,
        interestSectors,
        skills,
        updatedAt: Timestamp.fromDate(new Date()),
      });

      console.log("Profil utilisateur mis à jour avec succès");
      setOpenSnackbar(true);
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour du profil utilisateur :",
        error
      );
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <StyledPaper>
          <Typography variant="h5" gutterBottom>
            Profil Utilisateur
          </Typography>
          {currentUser.photoURL && (
            <Avatar
              src={currentUser.photoURL}
              alt="Photo de profil"
              sx={{ width: 100, height: 100, marginBottom: 2 }}
            />
          )}

          <Grid container spacing={3} className={Section}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Nom"
                name="name"
                value={profileInfo.name}
                onChange={handleProfileChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                name="email"
                value={profileInfo.email}
                onChange={handleProfileChange}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Téléphone"
                name="phone"
                value={profileInfo.phone}
                onChange={handleProfileChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Objectifs de carrière"
                name="careerGoals"
                value={profileInfo.careerGoals}
                onChange={handleProfileChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Pays"
                name="country"
                value={profileInfo.country}
                onChange={handleProfileChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Ville"
                name="city"
                value={profileInfo.city}
                onChange={handleProfileChange}
                fullWidth
              />
            </Grid>
          </Grid>

          <Divider className={Section} />

          <Typography variant="h6" gutterBottom>
            Secteurs d'intérêt
          </Typography>
          <List>
            {interestSectors.map((sector, index) => (
              <ListItem key={index}>
                <Autocomplete
                  options={[
                    "Technologie",
                    "Finance",
                    "Soins de santé",
                    "Éducation",
                    "Immobilier",
                    "Commerce de détail",
                    "Énergie",
                    "Télécommunications",
                    "Biens de consommation",
                    "Fabrication",
                    "Automobile",
                    "Transport et logistique",
                    "Aérospatiale",
                    "Construction",
                    "Médias et divertissement",
                    "Hôtellerie et tourisme",
                    "Agriculture",
                    "Pharmaceutique",
                    "Biotechnologie",
                    "Alimentation et boissons",
                  ]}
                  value={sector}
                  onChange={(event, newValue) =>
                    handleInterestSectorChange(index, newValue)
                  }
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  fullWidth
                />
                <Tooltip title="Supprimer">
                  <IconButton onClick={() => handleRemoveInterestSector(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
          <StyledButton
            startIcon={<AddIcon />}
            onClick={handleAddInterestSector}
          >
            Ajouter un secteur d'intérêt
          </StyledButton>

          <Divider className={Section} />

          <Typography variant="h6" gutterBottom>
            Compétences
          </Typography>
          <List>
            {skills.map((skill, index) => (
              <ListItem key={index}>
                <Autocomplete
                  options={[
                    "Leadership",
                    "Communication",
                    "Résolution de problèmes",
                    "Travail d'équipe",
                    "Pensée stratégique",
                    "Gestion de projet",
                    "Compétences analytiques",
                    "Créativité",
                    "Adaptabilité",
                    "Gestion du temps",
                    "Connaissance technique",
                    "Développement de logiciels",
                    "Design UX/UI",
                    "Marketing numérique",
                    "Analyse de données",
                    "Négociation",
                    "Service à la clientèle",
                    "Vente",
                    "Ressources humaines",
                    "Planification financière",
                  ]}
                  value={skill}
                  onChange={(event, newValue) =>
                    handleSkillChange(index, newValue)
                  }
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  fullWidth
                />
                <Tooltip title="Supprimer">
                  <IconButton onClick={() => handleRemoveSkill(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
          <StyledButton startIcon={<AddIcon />} onClick={handleAddSkill}>
            Ajouter une compétence
          </StyledButton>

          <Divider className={Section} />

          <Typography variant="h6" gutterBottom>
            Expériences professionnelles
          </Typography>
          <List>
            {experiences.map((experience, index) => (
              <ListItem key={index}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Entreprise"
                      name="company"
                      value={experience.company}
                      onChange={(e) => handleExperienceChange(index, e)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Durée"
                      name="duration"
                      value={experience.duration}
                      onChange={(e) => handleExperienceChange(index, e)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Poste"
                      name="position"
                      value={experience.position}
                      onChange={(e) => handleExperienceChange(index, e)}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Tooltip title="Supprimer">
                  <IconButton onClick={() => handleRemoveExperience(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
          <StyledButton startIcon={<AddIcon />} onClick={handleAddExperience}>
            Ajouter une expérience
          </StyledButton>

          <BottomDiv>
            <ButtonSpacing
              variant="contained"
              color="primary"
              onClick={handleProfileSubmit}
            >
              Enregistrer
            </ButtonSpacing>
            <ButtonSpacing
              variant="outlined"
              color="primary"
              onClick={() => navigate("/dashboard")}
            >
              Annuler
            </ButtonSpacing>
          </BottomDiv>

          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
          >
            <Alert onClose={handleCloseSnackbar} severity="success">
              Profil mis à jour avec succès !
            </Alert>
          </Snackbar>
        </StyledPaper>
      </Container>
    </ThemeProvider>
  );
};

export default Profile;
